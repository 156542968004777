import {
  CardGradientData,
  DataTable,
  DialogConfirmation,
  DialogValidationsMKT,
  SelectWithDefault,
  SkeletonGraph,
  WasteValidationsContainer,
} from "@/components/molecules";
import {
  BadgeInfoInterface,
  MKTValidationInterface,
  RowMKTValidation,
} from "@/interfaces";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowsRotate,
  faCertificate,
} from "@fortawesome/free-solid-svg-icons";
import {
  FormValidationMKT,
  LevelsCarousel,
  OverviewItem,
  TableRowExpanded,
} from "@/components/organisms";
import { Controller, useForm } from "react-hook-form";
import {
  dataServices,
  transform2GridMKTValidation,
  useCheckRefetch,
  useDataMKTValidation,
  useGraphicsData,
} from "@/hooks";
import {
  columnMKTValidation,
  columnMKTValidationGrid,
} from "@/components/columns";
import { useState } from "react";
import { useTransactionStates } from "@/hooks/useTransactionStates";
import { toast } from "@/components/atoms/shadcn/ui/use-toast";
import { Grid3X3, List } from "lucide-react";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useQuery } from "@tanstack/react-query";
import { useSession } from "next-auth/react";
import { Button } from "@/components/atoms/shadcn/ui/button";
import { FilterPerYearController } from "../FilterPerYearController/FilterPerYearController";
import { fetcherOverviewDemographics } from "@/pages/admins/escaner/overview";

const schemaNivelesValidation = z.object({
  id_validation: z.string({ required_error: "ID de Validation es requerido" }),
  name_activity: z
    .string({ required_error: "Nombre de la actividad es requerido" })
    .optional(),
  name_badge: z
    .string({ required_error: "Nombre de la insignia es requerido" })
    .optional(),
  description: z
    .string({ required_error: "Description es requerido" })
    .optional(),
  evidence: z.string({ required_error: "Evidencia es requerido" }),
  user_name: z
    .string({ required_error: "Nombre de Usuario es requerido" })
    .optional(),
  id_user: z.string({ required_error: "ID Usuario es requerido" }),
  date_insert: z.string({ required_error: "Fecha de Registro es requerido" }),
  image: z.string({ required_error: "Image es requerido" }).optional(),
  comments: z
    .string({
      required_error:
        "Comentarios es requerido para Validar / Rechazar la actividad",
    })
    .optional(),
});

export const LevelOverviewValidation = ({activeLevel}:{activeLevel:string}) => {
  const { data: session } = useSession();
  const { control, ranges, years, global } = useGraphicsData({
    keysRanges: [
      "states",
      "sub_brands_per_age",
      "waste_category_per_gender",
      "wastes_per_age",
      "brands_per_age",
      "waste_category_per_age",
      "ages",
      "brands_per_gender",
      "gender",
      "wastes_per_gender",
      "subbrands_per_gender",
      "towns",
    ],
    keysYear: [],
  });
  const { data: stats, refetch } = useQuery({
    queryKey: ["demographicsScan"],
    enabled: session?.user?.token ? true : false,
    queryFn: async () => {
      return session?.user?.token
        ? await fetcherOverviewDemographics({
            token: session?.user?.token!,
            ranges,
            global,
            years,
          })
        : null;
    },
  });

  useCheckRefetch(refetch, global, ranges, years);

  if ((!stats || !control ) && activeLevel == "") return <SkeletonGraph />;
  return (
    <WasteValidationsContainer
      key={`graphicCell`}
      className={`w-full`}
      variant={"default"}
      title={"Historial validaciones"}
      subtitle={"*Unicamente aplican las actividades de evidencia"}
      filter={
        // <FilterPerYearController
        //   name={""}
        //   control={
        //   }
        // />
        undefined
      }
    >
      <OverviewItem
        graphicType={"vertical_bar_comparative"}
        // data={selecDataStats({
        //   graphicType: item.graphicType,
        //   name_graphic_data: item.name_graphic_data,
        //   variant: item.variant,
        // })}
      />
    </WasteValidationsContainer>
    // <SkeletonGraph />
  );
};
