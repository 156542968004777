import {
  CardGradientData,
  DataTable,
  DialogConfirmation,
  DialogValidationsMKT,
  Searcher,
  SelectWithDefault,
} from "@/components/molecules";
import {
  BadgeInfoInterface,
  MKTValidationInterface,
  RowMKTValidation,
} from "@/interfaces";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowsRotate,
  faCertificate,
} from "@fortawesome/free-solid-svg-icons";
import {
  FormValidationMKT,
  LevelsCarousel,
  TableRowExpanded,
} from "@/components/organisms";
import { Controller, useForm } from "react-hook-form";
import {
  dataServices,
  transform2GridMKTValidation,
  useDataMKTValidation,
} from "@/hooks";
import {
  columnMKTValidation,
  columnMKTValidationGrid,
} from "@/components/columns";
import { useState } from "react";
import { useTransactionStates } from "@/hooks/useTransactionStates";
import { toast } from "@/components/atoms/shadcn/ui/use-toast";
import { Grid3X3, List } from "lucide-react";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useQuery } from "@tanstack/react-query";
import { useSession } from "next-auth/react";
import { Button } from "@/components/atoms/shadcn/ui/button";

const schemaNivelesValidation = z.object({
  id_validation: z.string({ required_error: "ID de Validation es requerido" }),
  name_activity: z
    .string({ required_error: "Nombre de la actividad es requerido" })
    .optional(),
  name_badge: z
    .string({ required_error: "Nombre de la insignia es requerido" })
    .optional(),
  description: z
    .string({ required_error: "Description es requerido" })
    .optional(),
  evidence: z.string({ required_error: "Evidencia es requerido" }),
  user_name: z
    .string({ required_error: "Nombre de Usuario es requerido" })
    .optional(),
  id_user: z.string({ required_error: "ID Usuario es requerido" }),
  date_insert: z.string({ required_error: "Fecha de Registro es requerido" }),
  image: z.string({ required_error: "Image es requerido" }).optional(),
  comments: z
    .string({
      required_error:
        "Comentarios es requerido para Validar / Rechazar la actividad",
    })
    .optional(),
});

export const LevelValidationTable = ({
  activeLevel,
}: {
  activeLevel: string;
}) => {
  const {
    control: control_badge,
    watch: watch_badge,
    setValue: setValue_badge,
  } = useForm<BadgeInfoInterface>({
    defaultValues: {
      selectedBadge: "null",
      selectedTab: "",
      typeBadge: "type_1",
      selectedTabCarousel: "overview",
      carouselIndex: 4,
    },
  });
  const selectedBadge = watch_badge("selectedBadge");

  const [buttonSelected, setButtonSelected] = useState(0);
  const {
    control: control_nivel_validations,
    handleSubmit: handleSubmit_nivel_validations,
    setValue: setValue_nivel_validations,
    reset,
    getValues,
    formState: { errors: errors_nivel_validation },
  } = useForm<MKTValidationInterface>({
    resolver: zodResolver(schemaNivelesValidation),
  });

  const {
    showGrid,
    setShowGrid,
    idxScan,
    getIdCellv2,
    rejected_control,
    rejected_handleSubmit,
    rejected_errors,
    isOpen,
    isOpenConfirm,
    isOpenRejected,
    handleCloseDialog,
    handleCloseConfirm,
    handleCloseRejected,
    handleRemove,
    handleRejected,
    getRowIndex,
    expanded,
    setExpanded,
    rejected_reset,
    rejected_watch,
  } = useTransactionStates<MKTValidationInterface>({ resetData });

  const {
    totals,
    requestData,
    control_date,
    pagination,
    onSubmitValidMKTValidation,
    onSubmitRejectedMKTValidation,
    onSubmitDeleteMKTValidation,
    refetch,
  } = useDataMKTValidation(9, buttonSelected, selectedBadge);

  function handleButtonSelected(number_btn: number) {
    setButtonSelected(number_btn);
    // if (number_btn == 4) {
    //   setColumDataTable(columnMKTValidationRejected(getRowIndex));
    // } else {
    //   setColumDataTable(columnMKTValidation(getRowIndex));
    // }
  }

  function resetData(data: MKTValidationInterface) {
    reset();
    setValue_nivel_validations("id_validation", data.id_validation);
    setValue_nivel_validations("name_activity", data.name_activity);
    setValue_nivel_validations("name_badge", data.name_badge);
    setValue_nivel_validations("description", data.description);
    setValue_nivel_validations("evidence", data.evidence);
    setValue_nivel_validations("user_name", data.user_name);
    setValue_nivel_validations("id_user", data.id_user);
    setValue_nivel_validations("date_insert", data.date_insert);
    setValue_nivel_validations("image", data.image);
  }

  return (
    <>
      <div className="flex flex-row w-full justify-around gap-3">
        <Searcher />
        <SelectWithDefault
          placeHolder="Actividad"
          className="w-1/3"
          selectedItems={[]}
          setValue={() => {}}
        />
        <div className="flex flex-row gap-3 items-center py-2 ">
          <Grid3X3
            className={`${
              showGrid ? "text-admins-text-active" : "text-admins-text-inactive"
            }`}
            onClick={() => {
              setShowGrid(true);
              pagination.onChangePageSize(9);
            }}
          />
          <List
            className={`${
              !showGrid
                ? "text-admins-text-active"
                : "text-admins-text-inactive"
            }`}
            onClick={() => {
              if (activeLevel !== "") {
                setShowGrid(false);
                pagination.onChangePageSize(10);
              }
            }}
          />
        </div>
      </div>

      {showGrid && selectedBadge && (
        <TableRowExpanded<RowMKTValidation>
          data={
            requestData?.data
              ? transform2GridMKTValidation(requestData.data)
              : []
          }
          columns={columnMKTValidationGrid(getIdCellv2)}
          renderSubComponent={({ row }) => (
            <FormValidationMKT
              key={row.parentId}
              handleRemove={handleRemove}
              data={row.original[idxScan]}
              handleRejected={handleSubmit_nivel_validations(async (data) => {
                try {
                  await onSubmitRejectedMKTValidation(data);
                  setExpanded({});
                  toast({
                    title: "Actualización con éxito",
                    description: `Se ha actualizado los valores con éxito`,
                  });
                  setExpanded({});
                  reset();
                } catch (error) {
                  toast({
                    title: "Ha ocurrido un error.",
                    description: `Lo siento, ha surgido un error al actualizar los valores, favor de intentarlo más tarde`,
                    variant: "destructive",
                  });
                  reset();
                  setExpanded({});
                  console.log(error);
                }
              })}
              isEditable={buttonSelected === 0}
              control={control_nivel_validations}
              errors={errors_nivel_validation}
              handleSubmit={handleSubmit_nivel_validations(async (data) => {
                try {
                  await onSubmitValidMKTValidation(data);
                  setExpanded({});
                  toast({
                    title: "Actualización con éxito",
                    description: `Se ha actualizado los valores con éxito`,
                  });
                  setExpanded({});
                  reset();
                } catch (error) {
                  toast({
                    title: "Ha ocurrido un error.",
                    description: `Lo siento, ha surgido un error al actualizar los valores, favor de intentarlo más tarde`,
                  });
                  reset();
                  setExpanded({});
                  console.log(error);
                }
              })}
            />
          )}
          getRowCanExpand={() => true}
          pagination={pagination}
          expanded={expanded}
          setExpanded={setExpanded}
        />
      )}
      {!showGrid && selectedBadge && (
        <DataTable
          pagination={pagination}
          columns={columnMKTValidation(getRowIndex)}
          data={requestData?.data ? requestData.data : []}
        />
      )}
      <DialogValidationsMKT
        isOpen={isOpen}
        onClose={handleCloseDialog}
        handleRemove={handleRemove}
        handleRejected={handleSubmit_nivel_validations(async (data) => {
          try {
            await onSubmitRejectedMKTValidation(data);
            setExpanded({});
            toast({
              title: "Actualización con éxito",
              description: `Se ha actualizado los valores con éxito`,
            });
            setExpanded({});
            reset();
            handleCloseDialog();
          } catch (error) {
            toast({
              title: "Ha ocurrido un error.",
              description: `Lo siento, ha surgido un error al actualizar los valores, favor de intentarlo más tarde`,
            });
            reset();
            setExpanded({});
            console.log(error);
            handleCloseDialog();
          }
        })}
        isEditable={buttonSelected === 0}
        control={control_nivel_validations}
        errors={errors_nivel_validation}
        handleSubmit={handleSubmit_nivel_validations(async (data) => {
          try {
            await onSubmitValidMKTValidation(data);
            setExpanded({});
            toast({
              title: "Actualización con éxito",
              description: `Se ha actualizado los valores con éxito`,
            });
            reset();
          } catch (error) {
            toast({
              title: "Ha ocurrido un error.",
              description: `Lo siento, ha surgido un error al actualizar los valores, favor de intentarlo más tarde`,
            });
            reset();
            setExpanded({});
            console.log(error);
          }
        })}
      />
      <DialogConfirmation
        handleSubmit={() => {
          try {
            const data = getValues();
            onSubmitDeleteMKTValidation(data);
            handleCloseConfirm();
            setExpanded({});
            toast({
              title: "Eliminación con éxito",
              description: `Se ha eliminado con éxito`,
            });
            reset();
          } catch (error) {
            toast({
              title: "Ha ocurrido un error.",
              description: `Lo siento, ha surgido un error al actualizar los valores, favor de intentarlo más tarde`,
            });
            reset();
            setExpanded({});
            console.log(error);
          }
        }}
        isOpen={isOpenConfirm}
        onClose={handleCloseConfirm}
      />
    </>
  );
};
