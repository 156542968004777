import { validation_image } from "@/assets";
import { CardBGGradient } from "@/components/atoms";
import Image from "next/image";

export const LevelCard = ({
  level_name,
  level_icon,
  isSelected,
  onClick,
}: {
  level_icon?: string;
  level_name?: string;
  isSelected: boolean;
  onClick?: () => void;
}) => {
  return (
    <CardBGGradient
      variant={"whiteDisabled"}
      className={`flex w-full px-3 gap-2 shadow-lg min-h-24 ${
        isSelected ? "border-admins-text-active border-2" : "border-none"
      }`}
      onClick={onClick}
    >
      <Image
        src={level_icon ? level_icon : validation_image}
        alt="waste_icon"
        width={65}
        height={65}
        style={{objectFit: "contain"}}
        className="m-3"
      />
      <div className="flex flex-col justify-around">
        <p className="text-admins-text-active font-bold">{level_name}</p>
      </div>
    </CardBGGradient>
  );
};
