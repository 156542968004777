import { CarouselItem } from "@/components/atoms/shadcn/ui/carousel";
import { WasteCard } from "@/components/molecules";
import { BadgeData, Waste } from "@/interfaces";
import { LevelCard } from "./LevelCard";

export const LevelCarouselItems = ({
  levels = [],
  setActive_level,
  active_level,
}: {
  levels?: BadgeData[];
  setActive_level: (id_waste: string) => void;
  active_level: string;
}) => {
  return levels?.map((uni_item, index) => (
    <CarouselItem
      className={`basis-full xl:basis-1/4 lg:basis-1/3 md:basis-1/2 mb-5`}
      key={`CarouselItem_${index * Math.random() * 100}`}
    >
      <LevelCard
        key={`badge_card_${index}`}
        level_icon={uni_item.icon !== null ? uni_item.icon : undefined}
        level_name={uni_item.name}
        isSelected={uni_item.id == active_level}
        onClick={() => setActive_level(uni_item.id)}
      />
    </CarouselItem>
  ));
};
