import { Skeleton } from "@/components/atoms/shadcn/ui/skeleton";

export function SkeletonGridValidation() {
  return (
    <div className="grid grid-cols-3 w-full p-10 gap-7 transition-all duration-500 ">
      <Skeleton className="w-full aspect-square" />
      <Skeleton className="w-full aspect-square" />
      <Skeleton className="w-full aspect-square" />
    </div>
  );
}
