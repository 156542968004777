import { CustomTag, SelectWithSearch } from "@/components/atoms";
import { Button } from "@/components/atoms/shadcn/ui/button";
import { Card } from "@/components/atoms/shadcn/ui/card";
import { Label } from "@/components/atoms/shadcn/ui/label";
import {
  CheckBoxWLabel,
  ControlledInput,
  ControlledInputImage,
  ControlledSelectWithDefault,
  MapAdmin,
  SelectWithDefault,
} from "@/components/molecules";
import {
  CC_Form,
  Schedules,
  Settlement,
  States,
  Towns,
  WasteCategoriesId,
  WasteId,
  WastePerCategoryID,
  ZipCodes,
} from "@/interfaces";
import { RefObject } from "react";
import {
  Control,
  Controller,
  FieldArrayWithId,
  UseFieldArrayAppend,
  UseFieldArrayRemove,
  UseFormGetValues,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";
import { MapRef } from "react-map-gl";
import { CheckBoxGroup } from "../CheckBoxGroup/CheckBoxGroup";
import { CirclePlus, PencilRuler } from "lucide-react";
import { Switch } from "@/components/atoms/shadcn/ui/switch";
import { CampaignsOptions } from "@/constants/campaignsData";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  IconDefinition,
  faFacebookF,
  faInstagram,
  faTiktok,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { faPhone } from "@fortawesome/free-solid-svg-icons";

type FormCreateCCProps = {
  isOpen?: boolean;
  control: Control<CC_Form, any>;
  fields: FieldArrayWithId<CC_Form, "wastes", "id">[];
  fields_socials: FieldArrayWithId<CC_Form, "socials", "id">[];
  watch: UseFormWatch<CC_Form>;
  getValue_cc: UseFormGetValues<CC_Form>;
  setValue_CC: UseFormSetValue<CC_Form>;
  remove: UseFieldArrayRemove;
  append: UseFieldArrayAppend<CC_Form, "wastes">;
  wastes?: WasteId[];
  wastes_categories: WasteCategoriesId[];
  wastes_perCategory: WastePerCategoryID[];
  states: States[];
  towns: Towns[];
  zip_codes: ZipCodes[];
  settlement: Settlement[];
  mapRef: RefObject<MapRef>;
  schedules?: Schedules[];
  campaigns?: CampaignsOptions[];
  handleDialogNewSchedule: () => void;
  handleCampaignChange: (id_campaign: string) => void;
  onSubmitValidCC: () => void;
};

const social_constant: {
  [key: string]: { icon: IconDefinition; name: string };
} = {
  facebook: { icon: faFacebookF, name: "Facebook" },
  instagram: { icon: faInstagram, name: "Instagram" },
  tiktok: { icon: faTiktok, name: "TikTok" },
  twitter: { icon: faTwitter, name: "Twitter" },
};

export const FormCreateCC = ({
  isOpen,
  control,
  fields,
  fields_socials,
  watch,
  append,
  wastes = [],
  wastes_categories = [],
  wastes_perCategory = [],
  states = [],
  towns = [],
  zip_codes = [],
  settlement = [],
  schedules = [],
  campaigns = [],
  mapRef,
  setValue_CC,
  handleDialogNewSchedule,
  onSubmitValidCC,
  handleCampaignChange,
}: FormCreateCCProps) => {
  return (
    <div className={`${isOpen ? "grid" : "hidden"} gap-6 grid-cols-4 w-full`}>
      <Card className="col-span-4 xl:col-span-3 grid grid-cols-2 gap-4 p-5">
        <h3 className="col-span-2 text-admins-button-green">
          Información General
        </h3>
        <div className="col-span-2 flex flex-col justify-between">
          <Label className="text-admins-text-active">Nombre</Label>
          <ControlledInput
            variant={"adminGray"}
            control={control}
            name="name"
            placeholder="Nombre"
          />
        </div>
        <div className="flex flex-col justify-between">
          <Label className="text-admins-text-active ">Valida</Label>
          <ControlledSelectWithDefault
            variant={"adminDisabled"}
            control={control}
            name="ticket"
            placeholder="Seleccione una opción"
            selectedItems={[
              { id: "yes", name: "Si" },
              { id: "no", name: "No" },
            ]}
          />
        </div>
        <div className="flex flex-col justify-between">
          <Label className="text-admins-text-active">
            Tipo de centro de acopio
          </Label>
          <ControlledSelectWithDefault
            variant={"adminDisabled"}
            control={control}
            name="center_type"
            placeholder="Tipo de centro"
            selectedItems={[
              { id: "centro_acopio", name: "Centro de Acopio" },
              { id: "punto_verde", name: "Punto Verde" },
              { id: "acopio_exclusivo", name: "Acopio Exclusivo" },
            ]}
          />
        </div>
        <div className="flex flex-col justify-between">
          <Label className="text-admins-text-active">
            <FontAwesomeIcon icon={faPhone} /> Teléfono
          </Label>
          <ControlledInput
            variant={"adminGray"}
            control={control}
            name="contact.phone"
            className="w-full"
            placeholder="Teléfono"
          />
        </div>
        <div className="flex flex-col justify-between">
          <Label className="text-admins-text-active">
            <FontAwesomeIcon icon={faPhone} /> Extensión
          </Label>
          <ControlledInput
            variant={"adminGray"}
            control={control}
            name="contact.phone_ext"
            className="w-full"
            placeholder="Extensión"
          />
        </div>
        {fields_socials.map((uni_social, index_social) => (
          <div
            key={`${uni_social.id}_${index_social}_social`}
            className="flex flex-col justify-between"
          >
            <Label className="text-admins-text-active">
              <FontAwesomeIcon
                icon={
                  social_constant[uni_social.social_medium_attributes.site].icon
                }
              />{" "}
              {social_constant[uni_social.social_medium_attributes.site].name}
            </Label>
            <ControlledInput
              variant={"adminGray"}
              control={control}
              name={`socials.${index_social}.social_medium_attributes.url`}
              className="w-full"
              placeholder={
                social_constant[uni_social.social_medium_attributes.site].name
              }
            />
          </div>
        ))}
      </Card>
      <Card className="col-span-4 xl:col-span-1 flex flex-col items-center justify-between p-4">
        <h3 className="col-span-2 text-admins-button-green">Imagen</h3>
        <ControlledInputImage
          control={control}
          name_image={"image"}
          name_file={"file"}
        />
      </Card>
      <Card className="col-span-4 xl:col-span-3 flex flex-wrap gap-4 justify-center p-5">
        <h3 className="w-1/2 text-admins-button-green">
          Días y horarios de operación
        </h3>
        <Button
          className="w-1/3"
          variant={"adminDownload"}
          onClick={handleDialogNewSchedule}
        >
          <CirclePlus className="text-admins-text-active mx-2 w-5 h-5" />
          Crear Formato de horario
        </Button>
        <Controller
          name={"schedule_id"}
          control={control}
          render={({ field: { name, onChange, value } }) => (
            <div className="grid grid-flow-col grid-rows-3 overflow-x-auto overflow-y-hidden auto-cols-min">
              {schedules.map((uni_sch_idx, index) => (
                <CheckBoxWLabel
                  key={`Option_${uni_sch_idx.slug}_${index}`}
                  className="min-w-[300px]"
                  name={uni_sch_idx.slug}
                  onClickBox={onChange}
                  value={value === uni_sch_idx.slug}
                  text_label={uni_sch_idx.text_label}
                  variant={"greenAdmin"}
                />
              ))}
            </div>
          )}
        />
        {/* <Button
          variant={"adminDownload"}
          disabled={
            watch("schedule_id") == "" || watch("schedule_id") == undefined
          }
          onClick={handleDialogNewSchedule}
        >
          <PencilRuler className="text-admins-text-active mx-2 w-5 h-5" />
          Editar
        </Button> */}
      </Card>
      <Card className="col-span-4 xl:col-span-1 flex flex-wrap gap-4 justify-center p-5">
        <h3 className="w-full text-admins-button-green">Beneficio</h3>
        <div className="flex flex-col gap-2">
          <Label className="text-admins-text-active">
            Selecciona un beneficio
          </Label>
          <ControlledSelectWithDefault
            key={`benefits_select`}
            control={control}
            placeholder="Beneficios"
            name="benefits"
            variant={"adminGreen"}
            selectedItems={[
              { id: "Ambiental", name: "Ambiental" },
              { id: "Efectivo", name: "Efectivo" },
              { id: "Ambos", name: "Ambos" },
            ]}
          />
        </div>
      </Card>
      <Card className="col-span-4 grid grid-cols-3 gap-4 p-5">
        <h3 className="col-span-3 text-admins-button-green">Ubicación</h3>

        <div className="order-1 xl:order-2 col-span-3 row-span-1 xl:col-span-1 grid grid-cols-1 gap-4 px-3">
          <h3 className="text-admins-button-green">Dirección</h3>
          <div>
            <Label className="text-admins-text-active">Estado</Label>
            <ControlledSelectWithDefault
              variant={"adminDisabled"}
              control={control}
              name="address.state_id"
              placeholder="Estado"
              selectedItems={states}
            />
          </div>
          <div>
            <Label className="text-admins-text-active">Ciudad</Label>
            <ControlledSelectWithDefault
              variant={"adminDisabled"}
              control={control}
              name="address.town_id"
              placeholder="Ciudad"
              selectedItems={towns}
            />
          </div>
          <div>
            <Label className="text-admins-text-active">Código Postal</Label>
            <ControlledSelectWithDefault
              control={control}
              name="address.zipcode_id"
              placeholder="Código Postal"
              selectedItems={zip_codes}
            />
          </div>
          <div>
            <Label className="text-admins-text-active">Colonia</Label>
            <ControlledSelectWithDefault
              variant={"adminDisabled"}
              control={control}
              name="address.settlement_id"
              placeholder="Colonia"
              selectedItems={settlement}
            />
          </div>
        </div>
        <div className="order-2 xl:order-1 col-span-3 xl:col-span-2  min-h-72">
          <MapAdmin
            watch={watch}
            mapRef={mapRef}
            setValue_CC={setValue_CC}
            control={control}
            scrollZoom
          />
        </div>
        <div className="order-3 col-span-3 grid grid-cols-2 gap-5">
          <div className="">
            <Label className="text-admins-text-active">Latitud</Label>
            <ControlledInput
              variant={"adminGray"}
              control={control}
              name="address.lat"
              type="number"
              placeholder="Latitud"
              validation={(value: string) => {
                // Contar los puntos en la cadena
                const dotCount = (value.match(/\./g) || []).length;
                const commaCount = (value.match(/,/g) || []).length;
                const numericValue = parseFloat(value);
                if (value == "") {
                  return true;
                } else if (dotCount > 1 && commaCount > 1) {
                  return "La latitud solo debe contener un punto o una coma";
                } else if (numericValue >= -90 && numericValue <= 90) {
                  return true;
                } else {
                  return "La latitud debe estar entre -90 y 90";
                }
              }}
            />
          </div>
          <div className="">
            <Label className="text-admins-text-active">Longitud</Label>
            <ControlledInput
              variant={"adminGray"}
              control={control}
              name="address.lng"
              type="number"
              typeof="number"
              placeholder="Longitud"
            />
          </div>
        </div>
        <div className="order-4 col-span-3 grid grid-cols-6 gap-4">
          <div className="col-span-3 xl:col-span-2">
            <Label className="text-admins-text-active">Calle</Label>
            <ControlledInput
              variant={"adminGray"}
              control={control}
              name="address.street"
              placeholder="Calle"
            />
          </div>
          <div className="col-span-3 xl:col-span-1">
            <Label className="text-admins-text-active"># Exterior</Label>
            <ControlledInput
              variant={"adminGray"}
              control={control}
              name="address.exterior"
              placeholder="# Exterior"
            />
          </div>
          <div className="col-span-3 xl:col-span-1">
            <Label className="text-admins-text-active"># Interior</Label>
            <ControlledInput
              variant={"adminGray"}
              control={control}
              name="address.interior"
              placeholder="# Interior"
            />
          </div>
        </div>
      </Card>
      <Card className="col-span-4 flex flex-col gap-3 p-5">
        <h3 className="col-span-2 text-admins-button-green">
          Selecciona los residuos que recibirán
        </h3>
        <div className="grid grid-cols-3 gap-10">
          {wastes_categories.map((uni_category) => (
            <div
              className="flex flex-col gap-2"
              key={`${uni_category.name}_${uni_category.id}`}
            >
              <Label className="text-admins-text-active">
                {uni_category.name}
              </Label>
              <SelectWithDefault
                setValue={(select: string) => {
                  if (
                    !fields.some((uni_waste) => uni_waste.waste_id == select)
                  ) {
                    append({ waste_id: select, _destroy: false });
                  }
                }}
                placeHolder={`${uni_category.name}`}
                selectedItems={
                  wastes_perCategory.find(
                    (categoryData) => categoryData.id == uni_category.id
                  )?.wastes ?? []
                }
              />
            </div>
          ))}
        </div>
        <div className="flex flex-wrap gap-2 w-full">
          <Label className="text-admins-text-active">Filtrar por Residuo</Label>
          <SelectWithSearch
            placeHolder={"Residuos"}
            value=""
            setValue={(select: string) => {
              if (!fields.some((uni_waste) => uni_waste.waste_id == select)) {
                append({ waste_id: select, _destroy: false });
              }
            }}
            selectedItems={wastes}
          />
          <div className="w-full flex flex-wrap gap-2">
            {fields &&
              fields.map(
                (item, index) =>
                  !watch(`wastes.${index}._destroy`) && (
                    <Controller
                      key={`fields_${item.id}_${index}`}
                      name={`wastes.${index}._destroy`}
                      control={control}
                      render={({ field: { name, value, onChange } }) => (
                        <CustomTag
                          bg_color={"white"}
                          colorIcon={"black"}
                          onClick={() => onChange(true)}
                          className="cursor-pointer"
                          tag2Show={
                            wastes.find(
                              (uni_waste) => uni_waste.id == item.waste_id
                            )?.name
                          }
                          key={`waste_${item.id}_customTag_${index}`}
                        />
                      )}
                    />
                  )
              )}
          </div>
        </div>
      </Card>
      <Card className="col-span-4 flex flex-col gap-3 p-5">
        <h3 className="col-span-2 text-admins-text-active">Campañas Activas</h3>
        <div className="flex flex-wrap gap-2 w-full">
          <Label className="text-admins-text-inactive">
            ¿Necesitas asociar este centro a alguna de las campañas activas?
          </Label>
          <div className="w-full grid grid-cols-2 gap-4">
            {campaigns.map((item_campaign, index) => (
              <div
                className="grid grid-cols-2 gap-4"
                key={`div_campana_${index * Math.random() * 100}`}
              >
                <Label> {item_campaign.name}</Label>
                <Switch
                  key={`campana_switch_${index * Math.random() * 100}`}
                  variantBG={"adminGreen"}
                  checked={watch("campaign")?.some(
                    (item_field) =>
                      item_field.campaign_id == item_campaign.id &&
                      item_field._destroy == false
                  )}
                  onCheckedChange={() => handleCampaignChange(item_campaign.id)}
                />
              </div>
            ))}
          </div>
        </div>
      </Card>
      <Button
        variant={"adminGray"}
        onClick={onSubmitValidCC}
        className="col-start-2 col-span-2"
      >
        Guardar
      </Button>
    </div>
  );
};
