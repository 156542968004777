import {
  Checkbox,
  checkBoxVariants,
} from "@/components/atoms/shadcn/ui/checkbox";
import { Label } from "@/components/atoms/shadcn/ui/label";
import { cn } from "@/lib/utils";
import { VariantProps } from "class-variance-authority";
import React, { DetailedHTMLProps, HTMLAttributes } from "react";
interface CheckBoxWLabelProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
    VariantProps<typeof checkBoxVariants> {
  onClickBox: (data: string) => void;
  value: boolean;
  text_label: string;
  name: string;
}
export const CheckBoxWLabel = ({
  onClickBox,
  value,
  text_label = "Test Check box",
  variant,
  name,
  className,
}: CheckBoxWLabelProps) => {
  return (
    <div className={cn("flex flex-row w-fit space-x-2", className)}>
      <Checkbox
        className=""
        variant={variant}
        id={name}
        checked={value}
        onClick={() => onClickBox(name)}
      />
      <Label
        htmlFor={name}
        className={`text-lg ${
          value ? "text-[#2CAD8F]" : "text-[#9291A5]"
        } font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70`}
      >
        {text_label}
      </Label>
    </div>
  );
};
