import {
  Carousel,
  CarouselContent,
  CarouselNext,
  CarouselPrevious,
} from "@/components/atoms/shadcn/ui/carousel";
import React, { DetailedHTMLProps, HTMLAttributes } from "react";
import { LevelCarouselItems } from "./LevelCarouselItems";
import { Badge, BadgeData, Waste } from "@/interfaces";
import { cn } from "@/lib/utils";

interface LevelsCarouselProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  levels?: BadgeData[];
  setActive_level: (id_waste: string) => void;
  active_level: string;
}

export const LevelsCarousel = ({
  levels,
  setActive_level,
  active_level,
  className,
}: LevelsCarouselProps) => {
  return (
    <div
      className={cn(
        "flex w-full justify-center lg:px-16 px-10 items-center",
        className
      )}
    >
      <Carousel opts={{ loop: true, align: "start" }} className="flex w-full ">
        <CarouselContent className="-ml-2 md:-ml-4">
          <LevelCarouselItems
            levels={levels}
            active_level={active_level}
            setActive_level={setActive_level}
          />
        </CarouselContent>
        <CarouselPrevious className="bg-white fill-white" />
        <CarouselNext className="bg-white fill-white" />
      </Carousel>
    </div>
  );
};
