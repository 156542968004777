import { CheckBoxWLabel } from "@/components/molecules/CheckBoxWLabel/CheckBoxWLabel";
import { cn } from "@/lib/utils";
import { DetailedHTMLProps, HTMLAttributes, useEffect } from "react";

interface CheckBoxGroupProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  options: {
    text_label: string;
    slug: string;
  }[];
  value: string;
  setValue: (value: string) => void;
}

export const CheckBoxGroup = ({
  options,
  value,
  setValue,
  className,
}: CheckBoxGroupProps) => {
  return (
    <div className={cn("flex flex-row space-x-3 justify-end", className)}>
      {options.map((option, index) => (
        <CheckBoxWLabel
          key={`Option_${option.slug}_${index * Math.random()}`}
          name={option.slug}
          onClickBox={setValue}
          value={value === option.slug}
          text_label={option.text_label}
          variant={"greenAdmin"}
        />
      ))}
    </div>
  );
};
