import { Skeleton } from "@/components/atoms/shadcn/ui/skeleton";

export function SkeletonGraph() {
  return (
    <div className="flex flex-col space-y-3 w-full">
      <div className="flex flex-row justify-around">
        <Skeleton className="h-4 w-[250px]" />
        <Skeleton className="h-4 w-[200px]" />
      </div>
      <Skeleton className="h-[30svh] w-full rounded-xl" />
    </div>
  );
}
