import { WasteValidationsContainer } from "@/components/molecules";
import { dataGraphicsType } from "@/interfaces";
import { OverviewItem } from "../OverviewItem/OverviewItem";

const dataGraphics_1: dataGraphicsType = [
  {
    title: "Total de Usuarios participantes en niveles",
    subtitle: "(al menos una actividad de algún nivel)",
    nameFilter: "graphsRanges.users",
    span: 1,
    graphicType: "card_num",
    name_graphic_data: "users",
  },
  {
    title: "Usuarios con todos los niveles completados",
    subtitle: undefined,
    nameFilter: "graphsYear.year_filter",
    span: 1,
    graphicType: "card_num",
    name_graphic_data: "validation_center_types",
  },
  {
    title: "Edad promedio de usuarios participantes",
    subtitle: undefined,
    nameFilter: "graphsYear.year_filter",
    span: 1,
    graphicType: "card_num",
    name_graphic_data: "validation_center_types",
  },
  {
    title: "Género promedio de usuarios participantes",
    subtitle: undefined,
    nameFilter: "graphsYear.year_filter",
    span: 1,
    graphicType: "card_num",
    name_graphic_data: "validation_center_types",
  },
  {
    title: "No. de usuarios participantes por nivel ",
    subtitle: undefined,
    nameFilter: "graphsYear.year_filter",
    span: 2,
    graphicType: "doughnut",
    name_graphic_data: "validation_center_types",
  },
];
export const OverviewLevelsGraphics = () => {
  return (
    <div className="w-full grid grid-cols-2 gap-4 px-3">
      {dataGraphics_1?.map((item, idx) => (
        <WasteValidationsContainer
          key={`graphicCell_${idx * Math.random() * 100}`}
          className={`${
            item.span == 1 ? "" : "col-span-2"
          } bg-admins-centers bg-opacity-15`}
          title={item.title}
          subtitle={item.subtitle}
          filter={
            //   <FilterPerYearController
            //     name={item.nameFilter}
            //     control={control}
            //   />
            <></>
          }
        >
          <OverviewItem
            graphicType={item.graphicType}
            data={
              // generateGraphic(
              //   stats_collection_centers as any,
              //   item.name_graphic_data,
              //   false,
              //   true
              // ) as any
              undefined
            }
          />
        </WasteValidationsContainer>
      ))}
    </div>
  );
};
